//
//
//
//
//
//
//
//
export default {
  name: 'TestTag',
  computed: {
    env: function env() {
      return process.env.NODE_ENV == 'development';
    }
  }
};