import Layout from "@/layout";
var agentRouter = {
  path: "/partner",
  component: Layout,
  name: "Partner",
  meta: {
    title: "合伙人管理",
    icon: "charging-pile",
    roles: ["admin:agent:list"]
  },
  children: [{
    path: "company",
    name: "Company",
    meta: {
      title: "公司管理"
    },
    component: {
      render: function render(h) {
        return h("router-view");
      }
    },
    redirect: "/partner/company/list",
    children: [{
      path: "list",
      name: "PartnerCompanyList",
      component: function component() {
        return import("@/views/partner/company/list");
      },
      meta: {
        title: "公司列表"
      }
    }, {
      path: "member",
      name: "PartnerCompanyMemberList",
      component: function component() {
        return import("@/views/partner/company/member");
      },
      hidden: true,
      meta: {
        title: "公司员工"
      }
    }, {
      path: "property",
      name: "PartnerCompanyPropertyList",
      component: function component() {
        return import("@/views/partner/company/property");
      },
      meta: {
        title: "结算小区"
      }
    }]
  }, {
    path: "finance",
    name: "Finance",
    meta: {
      title: "财务管理"
    },
    component: {
      render: function render(h) {
        return h("router-view");
      }
    },
    redirect: "/partner/finance/withdraw",
    children: [{
      path: "withdraw",
      name: "PartnerFinanceWithdraw",
      component: function component() {
        return import("@/views/partner/finance/withdraw");
      },
      meta: {
        title: "提现申请"
      }
    }, {
      path: "balanceRecord",
      name: "PartnerFinanceBalanceRecord",
      component: function component() {
        return import("@/views/partner/finance/balanceRecord");
      },
      meta: {
        title: "余额明细"
      }
    }]
  }, {
    path: "report",
    name: "Report",
    meta: {
      title: "数据报表"
    },
    component: {
      render: function render(h) {
        return h("router-view");
      }
    },
    redirect: "/partner/report/list",
    children: [{
      path: "list",
      name: "PartnerReportList",
      component: function component() {
        return import("@/views/partner/report/list");
      },
      meta: {
        title: "日报列表"
      }
    }, {
      path: "property",
      name: "PartnerReportPropertyList",
      component: function component() {
        return import("@/views/partner/report/propertyList");
      },
      meta: {
        title: "小区日报"
      }
    }]
  }]
};
export default agentRouter;