import Layout from '@/layout';
var sysRouter = {
  path: '/sys',
  component: Layout,
  name: 'Sys',
  meta: {
    title: '系统管理',
    icon: 'system',
    roles: ["admin:user:list"]
  },
  children: [{
    path: 'admin',
    name: 'sysAdmin',
    component: function component() {
      return import('@/views/sys/admin');
    },
    meta: {
      title: '管理员',
      roles: ['admin:user:list']
    }
  }, {
    path: 'role',
    name: 'sysRole',
    component: function component() {
      return import('@/views/sys/role');
    },
    meta: {
      title: '角色管理',
      roles: ['admin:role:list']
    }
  }]
};
export default sysRouter;