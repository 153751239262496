import request from '@/utils/request'; // 登录

export function loginByUsername(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data
  });
} // 登出

export function logout(data) {
  return request({
    url: '/me',
    method: 'post',
    data: data
  });
} // 个人信息

export function getUserInfo(data) {
  return request({
    url: '/me',
    method: 'get'
  });
} // 修改个人密码

export function updatePwd(data) {
  return request({
    url: '/passwords',
    method: 'put',
    data: data
  });
}