import Layout from '@/layout';
export default {
  path: '/others',
  name: 'others',
  hidden: true,
  component: Layout,
  children: [{
    path: 'welcome',
    name: 'Welcome',
    component: function component() {
      return import('@/views/others/welcome');
    },
    meta: {
      title: '欢迎首页'
    }
  }, {
    path: 'ownspace',
    name: 'Ownspace',
    component: function component() {
      return import('@/views/others/ownspace');
    },
    meta: {
      title: '个人账号'
    }
  } // { path: '*', redirect: '/err/nopage', hidden: true }
  ]
};