import axios from 'axios';
import store from '@/store';
import { Message, MessageBox } from 'element-ui';

var qs = require('qs');

var service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 60000,
  withCredentials: true,
  paramsSerializer: function paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat'
    });
  }
});
service.interceptors.request.use(function (config) {
  if (store.getters.token) {
    config.headers['Authorization'] = 'bearer ' + store.getters.token;
  }

  return config;
}, function (error) {
  Promise.reject(error);
});
service.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 500:
        Message({
          message: '服务器出错',
          type: 'error',
          duration: 2 * 1000
        });
        break;

      default:
        Message({
          message: error.response.data['message'] || error.response.status,
          type: 'error',
          duration: 3 * 1000
        });
    }
  }

  console.warn(error);
  return Promise.reject(error.response);
});
export default service;