import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var UserInfoKey = 'Admin-UserInfo';
var RouterKey = 'Admin-UserRouter';
var FileDetial = 'Admin-FileDetial'; // 获取cookie

export function getToken() {
  return Cookies.get(TokenKey);
} // 设置cookie

export function setToken(token) {
  return Cookies.set(TokenKey, token);
} // 一处cookie

export function removeToken() {
  return Cookies.remove(TokenKey);
} // 保存个人信息，姓名、角色

export function getUserInfoData() {
  return Cookies.get(UserInfoKey);
}
export function setUserInfoData(token) {
  return Cookies.set(UserInfoKey, token);
}
export function removeUserInfoData() {
  return Cookies.remove(UserInfoKey);
} // 路由

export function getUserRouter() {
  return Cookies.get(RouterKey);
}
export function setUserRouter(token) {
  return Cookies.set(RouterKey, token);
}
export function removeUserRouter() {
  return Cookies.remove(RouterKey);
} // 详情页

export function getUserDetail() {
  return Cookies.get(FileDetial);
}
export function setUserDetail(token) {
  return Cookies.set(FileDetial, token);
}
export function removeUserDetail() {
  return Cookies.remove(FileDetial);
}