import Layout from '@/layout';
var financeRouter = {
  path: '/finance',
  component: Layout,
  name: 'Finance',
  meta: {
    title: '财务管理',
    icon: 'Member',
    roles: ['admin:finance']
  },
  children: [{
    path: 'credit_record',
    name: 'CreditRecord',
    component: function component() {
      return import('@/views/finance/creditRecord/list');
    },
    meta: {
      title: '余额明细',
      roles: ['admin:finance']
    }
  }, {
    path: 'acSettle',
    name: 'AcSettle',
    component: function component() {
      return import('@/views/finance/acSettle');
    },
    meta: {
      title: '活动结算',
      roles: ['admin:finance.acSettle']
    }
  }, {
    path: 'acSettleDetail',
    name: 'AcSettleDetail',
    hidden: true,
    component: function component() {
      return import('@/views/finance/acSettleDetail');
    },
    meta: {
      title: '活动结算明细',
      roles: ['admin:finance.acSettle']
    }
  }, {
    path: 'turnover',
    name: 'Turnover',
    redirect: '/finance/turnover/settle',
    component: function component() {
      return import('@/views/finance/turnover/index');
    },
    meta: {
      title: '营业额结算',
      roles: ['admin:finance.turnover']
    },
    alwaysShow: true,
    children: [{
      path: 'bill',
      name: 'TurnoverBill',
      component: function component() {
        return import('@/views/finance/turnover/bill');
      },
      meta: {
        title: '账单列表',
        roles: ['admin:finance.turnover', 'admin:finance.turnover.bill']
      }
    }, {
      path: 'billDetail',
      name: 'TurnoverBillDetail',
      component: function component() {
        return import('@/views/finance/turnover/billDetail');
      },
      hidden: true,
      meta: {
        title: '账单明细',
        roles: ['admin:finance.turnover', 'admin:finance.turnover.billDetail']
      }
    }, {
      path: 'setting',
      name: 'TurnoverSetting',
      component: function component() {
        return import('@/views/finance/turnover/setting');
      },
      meta: {
        title: '结算设置',
        roles: ['admin:finance.turnover', 'admin:finance.turnover.setting']
      }
    }]
  }, {
    path: 'tranoverDetail',
    name: 'FinanceTranoverDetail',
    hidden: true,
    component: function component() {
      return import('@/views/finance/tranoverDetail');
    },
    meta: {
      title: '营业额结算明细',
      roles: ['admin:finance.tranoverDetail']
    }
  }, {
    path: 'tranoverRate',
    name: 'FinanceTranoverRate',
    component: function component() {
      return import('@/views/finance/tranoverRate');
    },
    meta: {
      title: '营业佣金配置',
      roles: ['admin:finance.tranoverRate']
    }
  }, {
    path: 'innochain',
    name: 'Innochain',
    component: function component() {
      return import('@/views/finance/innochain/index');
    },
    meta: {
      title: '创链对账',
      roles: ['admin:finance.innochain']
    },
    alwaysShow: true,
    children: [{
      path: 'settle',
      name: 'InnochainSettle',
      component: function component() {
        return import('@/views/finance/innochain/settle');
      },
      meta: {
        title: '日账单',
        roles: ['admin:finance.innochain', 'admin:finance.innochain.settle']
      }
    }, {
      path: 'settleDetail',
      name: 'InnochainSettleDetail',
      component: function component() {
        return import('@/views/finance/innochain/settleDetail');
      },
      meta: {
        title: '账单明细',
        roles: ['admin:finance.innochain', 'admin:finance.innochain.settle']
      }
    }, {
      path: 'withdrawal',
      name: 'InnochainWithdrawal',
      component: function component() {
        return import('@/views/finance/innochain/withdrawal');
      },
      meta: {
        title: '提现申请',
        roles: ['admin:finance.innochain', 'admin:finance.innochain.withdrawal']
      }
    }]
  }, {
    path: 'balance_record',
    name: 'BalanceRecord',
    component: function component() {
      return import('@/views/finance/balanceRecord/list');
    },
    meta: {
      title: '免费时长明细',
      roles: ['admin:finance']
    }
  }]
};
export default financeRouter;