import Layout from '@/layout';
var saleRouter = {
  path: "/sale",
  component: Layout,
  name: "Sale",
  meta: {
    title: "平台运营",
    icon: "charging-pile",
    roles: ["admin:sale"]
  },
  children: [{
    path: "unionpay",
    name: "Unionpay",
    meta: {
      title: "云闪付",
      roles: ["admin:sale:unionpay"]
    },
    component: {
      render: function render(h) {
        return h("router-view");
      }
    },
    redirect: "/sale/unionpay/trade",
    children: [{
      path: "paylog",
      name: "unionpayPaylogList",
      component: function component() {
        return import("@/views/sale/unionpay/paylog/list");
      },
      meta: {
        title: "交易记录"
      }
    }, {
      path: "setting",
      name: "unionpaySettingList",
      component: function component() {
        return import("@/views/sale/unionpay/setting/list");
      },
      meta: {
        title: "活动设置"
      }
    }, {
      path: "setting/edit",
      name: "SettingEdit",
      hidden: true,
      component: function component() {
        return import("@/views/sale/unionpay/setting/edit");
      },
      meta: {
        title: "活动设置编辑"
      }
    }]
  }, {
    path: "ypsub",
    component: {
      render: function render(h) {
        return h("router-view");
      }
    },
    meta: {
      title: "联通秒行卡",
      roles: ["admin:sale:ypsub"]
    },
    children: [{
      path: "list",
      name: "YpsubList",
      component: function component() {
        return import("@/views/sale/ypsub/list");
      },
      meta: {
        title: "交易记录"
      }
    }, {
      path: "list",
      name: "YpsubList",
      component: function component() {
        return import("@/views/sale/ypsub/list");
      },
      meta: {
        title: "统计"
      }
    }]
  }]
};
export default saleRouter;