import "/Users/paitony/Works/www/mx/charge_pile/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/paitony/Works/www/mx/charge_pile/node_modules/core-js/modules/es6.promise.js";
import "/Users/paitony/Works/www/mx/charge_pile/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/paitony/Works/www/mx/charge_pile/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'normalize.css/normalize.css';
import '@/permission';
import locale from 'element-ui/lib/locale/lang/zh-CN';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 主体scss

import '@/styles/index.scss';
import '@/icons';
import Moment from 'moment';
import * as echarts from "echarts";
Vue.prototype.$moment = Moment;
Vue.prototype.$echarts = echarts;
Vue.use(ElementUI, {
  locale: locale,
  size: 'small'
}); // 二维码

import VueQriously from 'vue-qriously';
Vue.use(VueQriously); // 地图
//import VueAMap from 'vue-amap';
// 

import iviewArea from 'iview-area';
Vue.use(iviewArea); // Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: '6117ac18aee509f70e95e8d0b9e2b6b5',
//   plugin: ['AMap.Scale', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geocoder'],
//   v: '1.4.4'
// });

Vue.directive('has', {
  inserted: function inserted(el, binding) {
    //获取缓存的按钮数组
    var array = store.getters.role_list; // 不存在则隐藏

    if (array === null || array.indexOf(binding.value) === -1) {
      el.style.display = 'none';
      el.parentNode.removeChild(el);
    }
  }
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');