import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout'; // router modules

import charge from "./modules/charge";
import member from "./modules/member";
import sys from "./modules/sys";
import others from "./modules/others";
import finance from "./modules/finance";
import advert from "./modules/advert";
import saleRouter from "./modules/sale";
import partner from "./modules/partner";
Vue.use(Router);
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  meta: {
    title: '登录'
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  hidden: true,
  redirect: '/welcome',
  children: [{
    path: 'welcome',
    name: 'Welcome',
    component: function component() {
      return import('@/views/others/welcome');
    },
    meta: {
      title: '欢迎首页',
      noCache: true,
      affix: true
    }
  }, {
    path: 'ownspace',
    name: 'Ownspace',
    component: function component() {
      return import('@/views/others/ownspace');
    },
    meta: {
      title: '个人账号',
      noCache: true,
      affix: true
    }
  }]
}];
export var asyncRoutes = [charge, member, finance, partner, advert, saleRouter, sys, {
  path: '*',
  redirect: '/welcome',
  hidden: true
}];
export default new Router({
  // mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});