var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  status: function status(state) {
    return state.user.status;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  setting: function setting(state) {
    return state.user.setting;
  },
  role_list: function role_list(state) {
    return state.user.roleList;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routers;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  }
};
export default getters;