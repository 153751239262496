import { asyncRoutes, constantRoutes } from '@/router';
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */

function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.indexOf(role) >= 0;
    });
  } else {
    return true;
  }
}
/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRoutes
 * @param roles
 */


function filterAsyncRouter(asyncRoutes, roles) {
  var accessedRouters = asyncRoutes.filter(function (route) {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles);
      }

      return true;
    }

    return false;
  }); // console.log(accessedRouters)

  return accessedRouters;
}

var permission = {
  state: {
    routers: constantRoutes,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRoutes.concat(routers);
    },
    SET_ADD_ROUTER: function SET_ADD_ROUTER(state, routers) {
      state.addRouters = [];
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        var roles = data; // console.log(roles)

        var accessedRouters;

        if (roles.indexOf('admin') >= 0) {
          accessedRouters = asyncRoutes;
        } else {
          accessedRouters = filterAsyncRouter(asyncRoutes, roles);
        }

        commit('SET_ROUTERS', accessedRouters);
        resolve();
      });
    }
  }
};
export default permission;