var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.env
    ? _c(
        "div",
        [
          _c("el-tag", { attrs: { type: "danger" } }, [
            _vm._v("\n    测试服\n  ")
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }