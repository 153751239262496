import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.search";
import _toConsumableArray from "/Users/paitony/Works/www/mx/charge_pile/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";

function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// fuse is a lightweight fuzzy-search module
// make search results more in line with expectations
import Fuse from 'fuse.js';
import path from 'path';
export default {
  name: 'HeaderSearch',
  data: function data() {
    return {
      search: '',
      options: [],
      searchPool: [],
      show: false,
      fuse: undefined
    };
  },
  computed: {
    routes: function routes() {
      return this.$store.getters.permission_routes;
    }
  },
  watch: {
    routes: function routes() {
      this.searchPool = this.generateRoutes(this.routes);
    },
    searchPool: function searchPool(list) {
      this.initFuse(list);
    },
    show: function show(value) {
      if (value) {
        document.body.addEventListener('click', this.close);
      } else {
        document.body.removeEventListener('click', this.close);
      }
    }
  },
  mounted: function mounted() {
    this.searchPool = this.generateRoutes(this.routes);
  },
  methods: {
    clicks: function clicks(event) {
      console.log('ss');
      event.stopPropagation();
      var show = !this.show;
      this.show = show;

      if (this.show) {
        this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.focus();
      }
    },
    close: function close() {
      this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.blur();
      this.options = [];
      this.show = false;
    },
    change: function change(val) {
      var _this = this;

      this.$router.push(val.path);
      this.search = '';
      this.options = [];
      this.$nextTick(function () {
        _this.show = false;
      });
    },
    initFuse: function initFuse(list) {
      this.fuse = new Fuse(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: 'title',
          weight: 0.7
        }, {
          name: 'path',
          weight: 0.3
        }]
      });
    },
    // Filter out the routes that can be displayed in the sidebar
    // And generate the internationalized title
    generateRoutes: function generateRoutes(routes) {
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var prefixTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var res = [];

      var _iterator = _createForOfIteratorHelper(routes),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var router = _step.value;

          // skip hidden router
          if (router.hidden) {
            continue;
          }

          var data = {
            path: path.resolve(basePath, router.path),
            title: _toConsumableArray(prefixTitle)
          };

          if (router.meta && router.meta.title) {
            // generate internationalized title
            // const i18ntitle = i18n.t(`route.${router.meta.title}`)
            var i18ntitle = router.meta.title;
            data.title = [].concat(_toConsumableArray(data.title), [i18ntitle]); // data.title = router.meta.title

            if (router.redirect !== 'noredirect') {
              // only push the routes with title
              // special case: need to exclude parent router without redirect
              res.push(data);
            }
          } // recursive child routes


          if (router.children) {
            var tempRoutes = this.generateRoutes(router.children, data.path, data.title);

            if (tempRoutes.length >= 1) {
              res = [].concat(_toConsumableArray(res), _toConsumableArray(tempRoutes));
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return res;
    },
    querySearch: function querySearch(query) {
      if (query !== '') {
        this.options = this.fuse.search(query);
      } else {
        this.options = [];
      }
    }
  }
};