import Layout from "@/layout";
var chargeRouter = {
  path: "/charge",
  component: Layout,
  name: "Charge",
  meta: {
    title: "充电桩",
    icon: "charging-pile",
    roles: ["admin:charging"]
  },
  children: [{
    path: "dashboard",
    component: function component() {
      return import("@/views/dashboard/index");
    },
    name: "Dashboard",
    // hidden: true,
    meta: {
      title: "首页",
      roles: ["admin:index.view"]
    }
  }, {
    path: "equipment",
    name: "Equipment",
    component: function component() {
      return import("@/views/charge/equipment/index");
    },
    meta: {
      title: "设备管理",
      roles: ["admin:charging:list"]
    },
    redirect: "/charge/equipment/list",
    children: [{
      path: "list",
      name: "EquipmentList",
      component: function component() {
        return import("@/views/charge/equipment/list");
      },
      meta: {
        title: "设备列表",
        roles: ["admin:charging:list"]
      }
    }, {
      path: "edit",
      name: "EquipmentEdit",
      hidden: true,
      component: function component() {
        return import("@/views/charge/equipment/edit");
      },
      meta: {
        title: "设备编辑",
        roles: ["admin:charging:add"]
      }
    }, {
      path: "occupy",
      name: "EquipmentOccupy",
      component: function component() {
        return import("@/views/charge/equipment/occupyTmplList");
      },
      meta: {
        title: "占位费模板",
        roles: ["admin:charging:list"]
      }
    }, {
      path: "firmware",
      name: "EquipmentFirmware",
      component: function component() {
        return import("@/views/charge/equipment/firmware");
      },
      meta: {
        title: "固件管理",
        roles: ["admin:firmware:list"]
      }
    }, {
      path: "meter",
      name: "EquipmentMeter",
      component: function component() {
        return import("@/views/charge/equipment/meter");
      },
      meta: {
        title: "抄表记录",
        roles: ["admin:meter:list"]
      }
    }, {
      path: "communic",
      name: "EquipmentCommunic",
      component: function component() {
        return import("@/views/charge/equipment/communic");
      },
      meta: {
        title: "通信日志",
        roles: ["admin:log:list"]
      }
    }]
  }, {
    path: "/order",
    name: "Order",
    component: function component() {
      return import("@/views/charge/order/index");
    },
    meta: {
      title: "订单管理",
      roles: ["admin:order:list"]
    },
    children: [{
      path: "list",
      name: "OrderList",
      component: function component() {
        return import("@/views/charge/order/list");
      },
      meta: {
        title: "订单列表",
        roles: ["admin:order:list"]
      }
    }, {
      path: "statement",
      name: "OrderStatement",
      component: function component() {
        return import("@/views/charge/order/statement");
      },
      meta: {
        title: "订单统计",
        roles: ["admin:order:statics"]
      }
    }, {
      path: "chargingData",
      name: "ChargingData",
      component: function component() {
        return import("@/views/charge/order/chargingData/index");
      },
      meta: {
        title: "充电数据",
        roles: ["admin:order:list"]
      }
    }, {
      path: "occupy",
      name: "OrderOccupyList",
      component: function component() {
        return import("@/views/charge/order/occupy/list");
      },
      meta: {
        title: "占位费计费",
        roles: ["admin:order:list"]
      }
    }]
  }, {
    path: "/statement",
    name: "Statement",
    component: function component() {
      return import("@/views/charge/statement/index");
    },
    meta: {
      title: "数据统计",
      roles: ["admin:statement:consume"]
    },
    alwaysShow: true,
    children: [{
      path: "consume",
      name: "StatementConsume",
      component: function component() {
        return import("@/views/charge/statement/consume");
      },
      meta: {
        title: "消费统计",
        roles: ["admin:statement:consume"]
      }
    }]
  }, {
    path: "/operate",
    name: "Operate",
    component: function component() {
      return import("@/views/charge/operate/index");
    },
    meta: {
      title: "运营方案",
      roles: ["admin:property:list"]
    },
    alwaysShow: true,
    children: [{
      path: "property",
      name: "OperateProperty",
      component: function component() {
        return import("@/views/charge/operate/property");
      },
      meta: {
        title: "物业小区",
        roles: ["admin:property:list"]
      }
    }, {
      path: "property/member",
      name: "PropertyMember",
      hidden: true,
      component: function component() {
        return import("@/views/charge/operate/property/member");
      },
      meta: {
        title: "小区用户",
        roles: ["admin:property:list"]
      }
    }, {
      path: "property/memberMonth",
      name: "PropertyMemberMonth",
      hidden: true,
      component: function component() {
        return import("@/views/charge/operate/property/month");
      },
      meta: {
        title: "小区用户月卡记录",
        roles: ["admin:property:list"]
      }
    }, {
      path: "property/staff",
      name: "PropertyStaff",
      component: function component() {
        return import("@/views/charge/operate/property/staff");
      },
      meta: {
        title: "小区员工",
        roles: ["admin:property:list"]
      }
    }, {
      path: "agent",
      name: "OperateAgent",
      component: function component() {
        return import("@/views/charge/operate/agent");
      },
      meta: {
        title: "代理商管理",
        roles: ["admin:agent:list"]
      }
    }]
  }, {
    path: "/alarm",
    name: "Alarm",
    component: function component() {
      return import("@/views/charge/alarm/index");
    },
    meta: {
      title: "报警管理",
      roles: ["admin:property:list"]
    },
    alwaysShow: true,
    children: [{
      path: "device",
      name: "AlarmDevice",
      component: function component() {
        return import("@/views/charge/alarm/device");
      },
      meta: {
        title: "报警设备",
        roles: ["admin:property:list"]
      }
    }, {
      path: "contact",
      name: "AlarmContact",
      component: function component() {
        return import("@/views/charge/alarm/contact");
      },
      meta: {
        title: "报警联系人",
        roles: ["admin:property:list"]
      }
    }, {
      path: "logs",
      name: "AlarmLogs",
      component: function component() {
        return import("@/views/charge/alarm/log");
      },
      meta: {
        title: "报警历史",
        roles: ["admin:agent:list"]
      }
    }]
  }]
};
export default chargeRouter;