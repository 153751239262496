var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("svg-icon", {
        attrs: {
          "icon-class": _vm.isFullscreen ? "exit-fullscreen" : "fullscreen"
        },
        nativeOn: {
          click: function($event) {
            return _vm.click($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }