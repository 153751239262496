/* eslint-disable */
import { loginByUsername, logout, getUserInfo } from '@/api/user';
import { getToken, setToken, removeToken, removeUserInfoData } from '@/utils/cache'; // import { Message } from 'element-ui'

var user = {
  state: {
    token: getToken(),
    roleList: [],
    setting: {
      articlePlatform: []
    },
    userInfo: {}
  },
  mutations: {
    SET_CODE: function SET_CODE(state, code) {
      state.code = code;
    },
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_SETTING: function SET_SETTING(state, setting) {
      state.setting = setting;
    },
    SET_STATUS: function SET_STATUS(state, status) {
      state.status = status;
    },
    SET_ROLES: function SET_ROLES(state, roleList) {
      state.roleList = roleList;
    },
    SET_USER_INFO: function SET_USER_INFO(state, info) {
      // console.log(info, 'qwerqwerqwer')
      state.userInfo = info;
    }
  },
  actions: {
    // 用户名登录
    LoginByUsername: function LoginByUsername(_ref, userInfo) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        loginByUsername(userInfo).then(function (response) {
          console.log(response);
          var token = response.access_token;
          commit('SET_TOKEN', token);
          setToken(token);
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    GetUserInfo: function GetUserInfo(_ref2) {
      var commit = _ref2.commit,
          state = _ref2.state;
      return new Promise(function (resolve, reject) {
        var token = state.token;
        getUserInfo(token).then(function (res) {
          // 所有
          // let roles = ['admin']
          console.log(res.data);
          var roles = res.data.permissions;
          commit('SET_ROLES', roles);
          commit('SET_USER_INFO', res.data);
          resolve(roles);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
          state = _ref3.state;
      return new Promise(function (resolve, reject) {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        removeToken();
        removeUserInfoData();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    },
    // 登出
    LogOut2: function LogOut2(_ref4) {
      var commit = _ref4.commit,
          state = _ref4.state;
      return new Promise(function (resolve, reject) {
        logout(state.token).then(function () {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          removeToken();
          removeUserInfoData();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  }
};
export default user;