import Layout from "@/layout";
var memberRouter = {
  path: "/member",
  component: Layout,
  name: "Member",
  meta: {
    title: "会员管理",
    icon: "Member",
    roles: ["admin:member:list"]
  },
  children: [{
    path: "list",
    name: "MemberList",
    component: function component() {
      return import("@/views/member/list");
    },
    meta: {
      title: "会员列表",
      roles: ["admin:member:list"]
    }
  }, {
    path: "add",
    name: "MemberAdd",
    hidden: true,
    component: function component() {
      return import("@/views/member/add");
    },
    meta: {
      title: "添加会员",
      roles: ["admin:member:add"]
    }
  }, {
    path: "level",
    name: "MemberLevel",
    component: function component() {
      return import("@/views/member/level/index");
    },
    meta: {
      title: "会员等级",
      roles: ["admin:member:level:list"]
    },
    children: [{
      path: "list",
      name: "MemberLevelList",
      component: function component() {
        return import("@/views/member/level/list");
      },
      meta: {
        title: "等级列表"
      }
    }, {
      path: "record",
      name: "MemberLevelRecord",
      component: function component() {
        return import("@/views/member/level/record");
      },
      meta: {
        title: "等级记录"
      }
    }]
  }, {
    path: "recharge",
    name: "MemberRecharge",
    component: function component() {
      return import("@/views/member/recharge");
    },
    meta: {
      title: "充值记录",
      roles: ["admin:recharge:list"]
    }
  }, {
    path: "card",
    name: "EquipmentCard",
    component: function component() {
      return import("@/views/charge/equipment/card");
    },
    meta: {
      title: "会员卡管理",
      roles: ["admin:card:list"]
    }
  }, {
    path: "/recharge",
    component: function component() {
      return import("@/views/member/recharge/index");
    },
    meta: {
      title: "充值管理",
      roles: ["admin:recharge:list"]
    },
    children: [{
      path: "rechargeAdmin",
      name: "MemberAdmin",
      component: function component() {
        return import("@/views/member/recharge/admin");
      },
      meta: {
        title: "充值管理员"
      }
    }, {
      path: "enough",
      name: "MemberEnough",
      component: function component() {
        return import("@/views/member/recharge/enough");
      },
      meta: {
        title: "满额立减"
      }
    }, {
      path: "acList",
      name: "MemberAcList",
      component: function component() {
        return import("@/views/member/recharge/acList");
      },
      meta: {
        title: "活动列表"
      }
    }, {
      path: "acEdit",
      name: "MemberAcEdit",
      hidden: true,
      component: function component() {
        return import("@/views/member/recharge/acEdit");
      },
      meta: {
        title: "编辑活动"
      }
    }, {
      path: "record",
      name: "MemberRecord",
      component: function component() {
        return import("@/views/member/recharge/rechargeRecord");
      },
      meta: {
        title: "管理员充值记录"
      }
    }]
  }]
};
export default memberRouter;