import Layout from '@/layout';
var advertRouter = {
  path: '/advert',
  component: Layout,
  name: 'Advert',
  meta: {
    title: '广告管理',
    icon: 'charging-pile',
    roles: ['admin:advert']
  },
  children: [{
    path: "wechat",
    name: "Wechat",
    meta: {
      title: "公众号",
      roles: ['admin:advert.wechat']
    },
    component: {
      render: function render(h) {
        return h("router-view");
      }
    },
    redirect: '/advert/wechat/slide',
    children: [{
      path: 'slide',
      name: 'wechatSlideList',
      component: function component() {
        return import('@/views/advert/wechat/slide/list');
      },
      meta: {
        title: '幻灯片',
        roles: ['admin:advert.wechat.slide']
      }
    }, {
      path: 'slide/edit',
      name: 'SlideEdit',
      hidden: true,
      component: function component() {
        return import('@/views/advert/wechat/slide/edit');
      },
      meta: {
        title: '幻灯片编辑'
      }
    }, {
      path: 'popup',
      name: 'wechatPopupList',
      component: function component() {
        return import('@/views/advert/wechat/popup/list');
      },
      meta: {
        title: '弹窗广告',
        roles: ['admin:advert.wechat.popup']
      }
    }, {
      path: 'popup/edit',
      name: 'PopupEdit',
      hidden: true,
      component: function component() {
        return import('@/views/advert/wechat/popup/edit');
      },
      meta: {
        title: '弹窗广告编辑'
      }
    }]
  }]
};
export default advertRouter;